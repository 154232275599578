import React, { ReactNode } from 'react'
import styled from 'styled-components'

export interface IButtonProps {
  className?: string
  children: ReactNode | ReactNode[]
}

const Button: React.FC<IButtonProps> = ({
  children,
  className,
  ...restProps
}) => (
  <StyledButton className={className} {...restProps}>
    {children}
  </StyledButton>
)

const StyledButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`

export default Button
