import React from 'react'
import styled from 'styled-components'
import LogoSvg from '../../img/logo.svg'

const Logo = () => (
  <LogoWrapper>
    <a href='/' aria-label='Flovan Consulting'>
      <LogoSvg />
    </a>
  </LogoWrapper>
)

const LogoWrapper = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0 6rem;

  @media (min-width: 900px) {
    margin-bottom: 5vw;
  }

  a {
    text-decoration: none;
  }

  svg {
    width: 35px;
    height: 26px;
    fill: ${props => props.theme.color.purple};
  }
`

export default Logo
