import React from 'react'
import { graphql } from 'gatsby'
import { PageQuery } from '../lib/types'
import Layout from '../components/Layout/Layout'
import Container from '../components/Layout/Container'
import Logo from '../components/Common/Logo'
import IntroductionSection from '../components/IntroductionSection'
import LinksSection, { ILink } from '../components/LinksSection'
import ClientSection from '../components/ClientSection'
import InfoSection, { IEthicItem } from '../components/InfoSection'
import OutroSection from '../components/OutroSection'
import Footer from '../components/Footer'

export interface IIndexPageTemplateProps {
  heading: string
  subheading: string
  intro: string
  secondaryIntro: string
  introButtonLabel: string
  projects: ILink[]
  links: ILink[]
  ethics: IEthicItem[]
  background: string
  outro: string
  outroButtonLabel: string
}

export const IndexPageTemplate = ({
  heading,
  subheading,
  intro,
  secondaryIntro,
  introButtonLabel,
  projects,
  links,
  ethics,
  background,
  outro,
  outroButtonLabel,
}: IIndexPageTemplateProps) => (
  <Layout>
    <header>
      <Container>
        <Logo />
      </Container>
    </header>
    <main>
      <IntroductionSection
        heading={heading}
        subheading={subheading}
        intro={intro}
        secondaryIntro={secondaryIntro}
        introButtonLabel={introButtonLabel}
      />
      <LinksSection projects={projects} links={links} />
      <ClientSection />
      <InfoSection ethics={ethics} background={background} />
      <OutroSection outro={outro} outroButtonLabel={outroButtonLabel} />
    </main>
    <Footer />
  </Layout>
)

const IndexPage = ({ data }: PageQuery<IIndexPageTemplateProps>) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      intro={frontmatter.intro}
      secondaryIntro={frontmatter.secondaryIntro}
      introButtonLabel={frontmatter.introButtonLabel}
      projects={frontmatter.projects}
      links={frontmatter.links}
      ethics={frontmatter.ethics}
      background={frontmatter.background}
      outro={frontmatter.outro}
      outroButtonLabel={frontmatter.outroButtonLabel}
    />
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
        intro
        secondaryIntro
        introButtonLabel
        projects {
          title
          description
          url
        }
        links {
          title
          description
          url
        }
        ethics {
          title
          description
        }
        background
        outro
        outroButtonLabel
      }
    }
  }
`
