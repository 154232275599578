import React from 'react'
import marked from 'marked'
import styled from 'styled-components'
import Container from '../components/Layout/Container'
import ArrowButton from '../components/Common/ArrowButton'
import { IIndexPageTemplateProps } from '../templates/index-page'

export interface ILink {
  title: string
  description: string
  url: string
}

export interface ILinksSectionProps {
  projects: ILink[]
  links: ILink[]
}

const LinksSection = ({ projects, links }: ILinksSectionProps) => (
  <Section>
    <LinksContainer>
      <div>
        <h3>Selected work</h3>
        {projects.map(({ title, description, url }) => (
          <StyledArrowButton
            key={`projectLink_${title.replace(' ', '_')}`}
            label={title}
            subLabel={description}
            href={url}
            hollow
          />
        ))}
      </div>
      <div>
        <h3>Slide into my DM's</h3>
        {links.map(({ title, description, url }) => (
          <StyledArrowButton
            key={`link_${title.replace(' ', '_')}`}
            label={title}
            subLabel={description}
            href={url}
            hollow
          />
        ))}
      </div>
    </LinksContainer>
  </Section>
)

const Section = styled.section`
  padding: 3rem 0;
  font-size: 1.5rem;
  background-color: ${props => props.theme.color.offBlack};

  @media (min-width: 900px) {
    padding: 6rem 0;
  }
`

const LinksContainer = styled(Container)`
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
  }

  > div {
    @media (min-width: 800px) {
      flex-basis: calc(50% - 1.5rem);
    }

    @media (min-width: 950px) {
      flex-basis: calc(50% - 3rem);
    }
  }

  > div:first-child {
    color: ${props => props.theme.color.purple};
    margin-bottom: 3rem;

    @media (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  > div:last-child {
    color: ${props => props.theme.color.cyan};
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 1rem;
    border-bottom: 1px solid currentColor;
    text-transform: uppercase;
  }
`

const StyledArrowButton = styled(ArrowButton)`
  display: block;
  margin-left: -1rem;
  margin-right: -1rem;
`

export default LinksSection
