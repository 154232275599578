import React, { ReactNode } from 'react'
import styled from 'styled-components'

export interface IContainerProps {
  className?: string
  children: ReactNode | ReactNode[]
}

const Container = ({ children, className }: IContainerProps) => (
  <CenteredDiv className={className}>{children}</CenteredDiv>
)

const CenteredDiv = styled.div`
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 1.5rem;

  @media (min-width: 500px) {
    padding: 0 3rem;
  }

  @media (min-width: 1070px) {
    padding: 0 10vw;
  }
`

export default Container
