import React from 'react'
import styled from 'styled-components'
import marked from 'marked'
import ArrowButton, { ArrowButtonType } from '../components/Common/ArrowButton'
import Container from './Layout/Container'

export interface IOutroSectionProps {
  outro: string
  outroButtonLabel: string
}

const OutroSection: React.FC<IOutroSectionProps> = ({
  outro,
  outroButtonLabel,
}) => (
  <Section>
    <StyledContainer>
      <div dangerouslySetInnerHTML={{ __html: marked(outro) }}></div>
      <ArrowButton
        label={outroButtonLabel}
        subLabel='Send me an electronic mail'
        variant={ArrowButtonType.Cyan}
        href='mailto:hello@flovan.be'
      />
    </StyledContainer>
  </Section>
)

const Section = styled.section`
  padding: 3rem 0;
  font-size: 1.25rem;
  background-color: ${props => props.theme.color.offBlack};

  @media (min-width: 900px) {
    padding: 6rem 0;
  }
`

const StyledContainer = styled(Container)`
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }

  div:first-child {
    flex: 1;
    margin-right: 3rem;

    @media (min-width: 950px) {
      margin-right: 6rem;
    }
  }

  p {
    margin: 0 0 3rem;

    @media (min-width: 900px) {
      margin-bottom: 0;
    }
  }
`

export default OutroSection
