import React, { ReactElement } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import theme from '../../lib/theme'
import SpaceGroteskRegularWoff2Font from '../../fonts/SpaceGrotesk-Regular.woff2'
import SpaceGroteskRegularWoffFont from '../../fonts/SpaceGrotesk-Regular.woff'
import SpaceGroteskBoldWoff2Font from '../../fonts/SpaceGrotesk-Bold.woff2'
import SpaceGroteskBoldWoffFont from '../../fonts/SpaceGrotesk-Bold.woff'
import LemonTuesdayWoff2Font from '../../fonts/LemonTuesday.woff2'
import LemonTuesdayWoffFont from '../../fonts/LemonTuesday.woff'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SpaceGrotesk';
    font-weight: regular;
    font-display: swap;
    src: url(${SpaceGroteskRegularWoff2Font}) format('woff2'),
         url(${SpaceGroteskRegularWoffFont}) format('woff');
  }

  @font-face {
    font-family: 'SpaceGrotesk';
    font-weight: bold;
    font-display: swap;
    src: url(${SpaceGroteskBoldWoff2Font}) format('woff2'),
         url(${SpaceGroteskBoldWoffFont}) format('woff');
  }

  @font-face {
    font-family: 'Lemon Tuesday';
    font-weight: regular;
    font-display: swap;
    src: url(${LemonTuesdayWoff2Font}) format('woff2'),
         url(${LemonTuesdayWoffFont}) format('woff');
  }

  html {
    box-sizing: border-box;
    font-family: SpaceGrotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-rendering: optimizeLegibility;
    color: ${props => props.theme.color.white};
    line-height: 1.35;
  }

  *,
  *:after,
  *:before {
    box-sizing: inherit;
    line-height: inherit;
    font-size: inherit;
  }

  body {
    margin: 0;
    background-color: ${props => props.theme.color.black};
  }

  main,
  details,
  summary {
    display: block;
  }

  pre,
  textarea {
    overflow: auto;
  }

  textarea {
    resize: vertical;
  }

  html,
  input,
  textarea,
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  input,
  select,
  textarea {
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
  }

  button {
    overflow: visible;
  }

  table {
    border-collapse: 0;
    border-spacing: 0;
    width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  a {
    color: currentColor;
    text-decoration-thickness: 1px;
    text-underline-offset: .1rem;
  }

  article {
    * {
      margin: 0;
    }

    * + h2,
    * + h3,
    * + h4,
    * + ul,
    * + li,
    * + button,
    * + a,
    * + p {
      margin-top: 1.5rem;
    }
  }
`

export interface ILayoutProps {
  children: ReactElement | ReactElement[]
}

const Layout: React.FC<ILayoutProps> = props => {
  const { title, description } = useSiteMetadata()

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Helmet>
          <html lang='en' />
          <title>{title}</title>
          <meta name='description' content={description} />
          <meta name='theme-color' content='#ab5ec9' />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16.png'
          />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ab5ec9' />
        </Helmet>
        <GlobalStyle />
        {props.children}
      </React.Fragment>
    </ThemeProvider>
  )
}

export default Layout
