import React from 'react'
import styled from 'styled-components'
import marked from 'marked'
import Container from './Layout/Container'

export interface IEthicItem {
  title: string
  description?: string
}

export interface IClientSectionProps {
  ethics: IEthicItem[]
  background: string
}

const ClientSection: React.FC<IClientSectionProps> = ({
  ethics,
  background,
}) => (
  <Section>
    <StyledContainer>
      <article>
        {ethics.length > 0 && (
          <div>
            <h3>Work ethics</h3>
            <ul>
              {ethics.map(({ title, description }) => (
                <li>
                  <strong>{title}</strong>
                  {description && <span>{description}</span>}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div>
          <h3>A little background</h3>
          <div dangerouslySetInnerHTML={{ __html: marked(background) }}></div>
        </div>
      </article>
    </StyledContainer>
  </Section>
)

const Section = styled.section`
  padding: 3rem 0;
  font-size: 1.25rem;
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.purple};

  @media (min-width: 900px) {
    padding: 6rem 0;
  }
`

const StyledContainer = styled(Container)`
  h3 {
    margin: 0 0 2rem;
    font-size: 1rem;
    border-bottom: 1px solid currentColor;
    text-transform: uppercase;
  }

  article {
    @media (min-width: 800px) {
      display: flex;
      justify-content: space-between;
    }
  }

  article > div {
    :first-child {
      margin-bottom: 3rem;
    }

    @media (min-width: 800px) {
      flex-basis: calc(50% - 1.5rem);

      :first-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: 950px) {
      flex-basis: calc(50% - 3rem);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    position: relative;
    padding-left: 3rem;

    ::before {
      content: '';
      position: absolute;
      top: 0.8rem;
      left: 0;
      height: 1px;
      width: 2rem;
      background-color: currentColor;
    }

    + li {
      margin-top: 1rem;
    }

    strong {
      display: block;
    }
  }
`

export default ClientSection
