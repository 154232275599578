import React from 'react'
import marked from 'marked'
import styled from 'styled-components'
import Container from '../components/Layout/Container'
import ArrowButton from '../components/Common/ArrowButton'
import { IIndexPageTemplateProps } from '../templates/index-page'

type IIntroductionSectionProps = Pick<
  IIndexPageTemplateProps,
  'heading' | 'subheading' | 'intro' | 'secondaryIntro' | 'introButtonLabel'
>

const IntroductionSection = ({
  heading,
  subheading,
  intro,
  secondaryIntro,
  introButtonLabel,
}: IIntroductionSectionProps) => (
  <section>
    <IntroductionTitle>
      <dl>
        <dt>{heading}</dt>
        <dd>{subheading}</dd>
      </dl>
    </IntroductionTitle>
    <IntroductionContainer>
      <article>
        <div dangerouslySetInnerHTML={{ __html: marked(intro) }}></div>
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: marked(secondaryIntro) }}
          ></div>
          <ArrowButton
            label={introButtonLabel}
            subLabel='Send me an electronic mail'
            href='mailto:hello@flovan.be'
          />
        </div>
      </article>
    </IntroductionContainer>
  </section>
)

const IntroductionTitle = styled.h2`
  margin: 0 0 3rem;
  font-size: 4rem;
  line-height: 0.9;

  @media (min-width: 900px) {
    font-size: 7vw;
    margin-bottom: 0;
  }

  dl {
    margin: 0;
    /* overflow-x: hidden; */

    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  dt,
  dd {
    margin: 0 1.5rem;

    @media (min-width: 500px) {
      margin: 0 3rem;
    }

    @media (min-width: 900px) {
      margin: 0;
      max-width: 45%;
    }
  }

  dt {
    font-weight: bold;
    margin-bottom: 3rem;

    @media (min-width: 900px) {
      margin-bottom: 0;
      /* transform: translateX(-0.7vw); */
    }
  }

  dd {
    font-family: Lemon Tuesday, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    font-weight: normal;

    @media (min-width: 900px) {
      text-align: right;
      /* transform: translateX(0.7vw); */
    }
  }
`

const IntroductionContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.5rem;

  @media (min-width: 900px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  article {
    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
    }

    > div {
      @media (min-width: 900px) {
        flex-basis: calc(50% - 1.5rem);
      }

      @media (min-width: 950px) {
        flex-basis: calc(50% - 3rem);
      }
    }

    > div:last-child {
      margin-top: 1.5rem;

      a {
        margin-top: 3rem;
      }

      @media (min-width: 900px) {
        margin-top: 0;

        a {
          margin-top: 1.5rem;
        }
      }
    }
  }
`

export default IntroductionSection
