import React, { ReactNode } from 'react'
import styled from 'styled-components'
import ArrowIcon from '../../img/icon--arrow.svg'
import Button from './Button'

export interface IArrowButtonProps {
  label: string
  subLabel: string
  className?: string
  href?: string
  hollow?: boolean
  variant?: ArrowButtonType
}

export enum ArrowButtonType {
  Purple = 'purple',
  Cyan = 'cyan',
}

interface ILinkProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  target?: string
  href?: string
}

const ArrowButton: React.FC<IArrowButtonProps> = ({
  label,
  subLabel,
  className,
  href,
  hollow = false,
  variant = ArrowButtonType.Purple,
}) => {
  const linkProps: ILinkProps = href ? { as: 'a', href } : {}
  return (
    <StyledButton
      className={className}
      hollow={hollow}
      variant={variant}
      {...linkProps}
    >
      <span>
        <span>
          <strong>{label}</strong>
          <span>{subLabel}</span>
        </span>
        <ArrowIcon />
      </span>
    </StyledButton>
  )
}

interface IStyledButtonProps {
  variant: ArrowButtonType
  hollow: boolean
}

const StyledButton = styled(Button)<IStyledButtonProps>`
  display: inline-block;
  line-height: 1;
  text-align: left;
  text-decoration: none;

  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: ${props =>
      props.hollow ? 'currentColor' : props.theme.color.black};
    background-color: ${props =>
      props.hollow ? 'transparent' : props.theme.color[props.variant]};
  }

  > span > span {
    display: block;
  }

  :hover > span {
    color: ${props => props.theme.color.white};
    background: repeating-linear-gradient(
      45deg,
      #363ac9 0,
      #363ac9 1rem,
      #ab5ec9 1rem,
      #ab5ec9 2rem,
      #c65373 2rem,
      #c65373 3rem,
      #d0c271 3rem,
      #d0c271 4rem,
      #9bd0d0 4rem,
      #9bd0d0 5rem
    );
    background-repeat: repeat-x;
    background-size: 7rem 100%;
    background-position: 0% 0%;
    transform: rotate(-4deg);
    animation: twitchup 67ms, moveIt 1s linear infinite;
  }

  :nth-child(odd):hover > span {
    transform: rotate(4deg);
    animation: twitchdown 67ms, moveIt 1s linear infinite;
  }

  :nth-child(even):hover > span {
    transform: rotate(-4deg);
    animation: twitchup 67ms, moveIt 1s linear infinite;
  }

  strong {
    display: block;
  }

  strong + span {
    font-style: normal;
    font-size: 1.25rem;
  }

  svg {
    fill: currentColor;
    width: 2rem;
    height: 2rem;
    margin-left: 3rem;
  }

  @keyframes moveIt {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 7rem 0;
    }
  }

  @keyframes twitchup {
    60% {
      transform: rotate(-6deg);
    }
    100% {
      transform: rotate(-4deg);
    }
  }

  @keyframes twitchdown {
    60% {
      transform: rotate(6deg);
    }
    100% {
      transform: rotate(4deg);
    }
  }
`

export default ArrowButton
