import React from 'react'
import styled from 'styled-components'
import IcappsLogo from '../img/logo--icapps.svg'
import TelenetLogo from '../img/logo--telenet.svg'
import VlaanderenLogo from '../img/logo--vlaanderen.svg'
import ReferenceLogo from '../img/logo--reference.svg'
import AdagioLogo from '../img/logo--adagio.svg'
import Container from './Layout/Container'

const clientLogos = [
  IcappsLogo,
  TelenetLogo,
  VlaanderenLogo,
  ReferenceLogo,
  AdagioLogo,
]

const ClientSection = () => (
  <Section>
    <StyledContainer>
      <h3>I have worked for</h3>
      <div>
        {clientLogos.map((LogoSvg, index) => (
          <LogoSvg key={`client_${index}`} />
        ))}
      </div>
    </StyledContainer>
  </Section>
)

const Section = styled.section`
  padding: 3rem 0;
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.cyan};

  @media (min-width: 900px) {
    padding: 6rem 0;
  }
`

const StyledContainer = styled(Container)`
  h3 {
    margin: 0 0 1rem;
    font-size: 1rem;
    border-bottom: 1px solid currentColor;
    text-transform: uppercase;

    @media (min-width: 900px) {
      max-width: calc(50% - 3rem);
    }
  }

  div {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }

  svg {
    margin: 1rem 3rem 0 0;
    flex-basis: 130px;
    flex-shrink: 1;
    max-height: 40px;
    fill: currentColor;
  }
`

export default ClientSection
