import React from 'react'
import styled from 'styled-components'
import marked from 'marked'
import ArrowButton, { ArrowButtonType } from '../components/Common/ArrowButton'
import Container from './Layout/Container'

const FooterSection: React.FC = () => (
  <Footer>
    <Container>
      <span>
        There is nothing else here. In the wise words of{' '}
        <a href='//thinko.com'>Thinko</a>: this is the butt of the website.
      </span>
    </Container>
  </Footer>
)

const Footer = styled.footer`
  padding: 1rem 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
`

export default FooterSection
