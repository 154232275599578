const theme = {
  color: {
    black: '#08000A',
    offBlack: '#131313',
    white: '#FFFFFF',
    purple: '#AB5EC9',
    cyan: '#9BD0D0',
  },
}

export default theme
